export class MainView {

    init() {

        function firstViewLoad() {
            const spacer = document.getElementById('js-spacer');
            const mvH = spacer.clientHeight
            console.log(mvH);
            spacer.style.marginBottom = mvH + 'px';
            // const scrollTop = window.scrollY || document.documentElement.scrollTop;
            // const mv = document.querySelectorAll('.p-mv');
            // if( scrollTop < 30){
            //     mv.forEach(obj => {
            //         obj.classList.add('is-show');
            //     });
            // }
        }
        firstViewLoad();
        
        function topAnimation() {
            const spacer = document.getElementById('js-spacer');
            const mvH = spacer.clientHeight
            // console.log(mvH);
            // spacer.style.marginBottom = mvH + 'px';

            const mv = document.querySelectorAll('#js-mv');
            const copy = document.querySelectorAll('.js-copy');
            const lead = document.querySelectorAll('.js-lead-pc');
            const overlay = document.querySelectorAll('.js-overlay');
            
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            if( scrollTop > 30){
                copy.forEach(obj => {
                    obj.classList.add('is-hide');
                });
                lead.forEach(obj => {
                    obj.classList.add('is-show');
                });
                overlay.forEach(obj => {
                    obj.classList.add('is-show');
                });
                if( scrollTop > mvH ){
                    mv.forEach(obj => {
                        obj.classList.remove('is-fixed');
                        spacer.style.marginBottom = '0px';
                    });
                } else {
                    mv.forEach(obj => {
                        obj.classList.add('is-fixed');
                        spacer.style.marginBottom = mvH + 'px';
                    });
                }
            } else {
                copy.forEach(obj => {
                    obj.classList.remove('is-hide');
                });
                lead.forEach(obj => {
                    obj.classList.remove('is-show');
                });
                overlay.forEach(obj => {
                    obj.classList.remove('is-show');
                });
            }
        }
        window.addEventListener('scroll', topAnimation);
    }
}
import gsap from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

export class Common {

    init() {

        // wp側で実装のためbuild時はコメントアウト
        /* =================================================== */
        // 慣性スクロール
        /* =================================================== */

        function inertialScroll() {
            const container = document.querySelector('#js-scroll-container');
            if(container){
                const height = container.clientHeight;//ページ内要素の高さを取得
                document.body.style.height = `${height}px`;//bodyタグに高さ付与
                gsap.to(container, {
                    //ページ内要素の高さ - ウインドウの高さ
                    y: -(height - document.documentElement.clientHeight),
                    ease: 'none',
                    scrollTrigger: {
                        trigger: document.body,
                        start: 'top top',
                        end: 'bottom bottom',
                        scrub: 1,
                    },
                });
            }
        }
        window.onload = function() {
            inertialScroll();
        };


        /* =================================================== */
        // fade in
        /* =================================================== */
        // 初期値を指定
        gsap.set('.js-oneByOne', {
            opacity: 0,
            y: 50,
        });
        ScrollTrigger.batch(".js-oneByOne", {
            onEnter: (batch) => gsap.to(batch, {
                delay: 0.5, // アニメーションするまでの時間
                duration: 0.5, // アニメーションが始まって終わるまでの時間
                autoAlpha: 1,
                y: 0,
                stagger: 0.1, // 時差の間隔
            }),
            start: 'top 70%',
        });


        /* =================================================== */
        // ハンバーガーメニュー
        /* =================================================== */
        const menu = document.querySelector('#js-menu');
        const nav = document.querySelector('#js-nav');
        menu.addEventListener('click', function () {
            menu.classList.toggle('is-active');
            nav.classList.toggle('is-active');

            const container = document.querySelector('#js-scroll-container');
            //alert(container.clientHeight);
        });


        /* =================================================== */
        // slideUp, slideDown, slideToggle関数を定義
        /* =================================================== */
        // 要素をスライドしながら非表示
        const slideUp = (el, duration = 300) => {
            el.style.height = el.offsetHeight + "px";
            el.offsetHeight;
            el.style.transitionProperty = "height, margin, padding";
            el.style.transitionDuration = duration + "ms";
            el.style.transitionTimingFunction = "ease";
            el.style.overflow = "hidden";
            el.style.height = 0;
            el.style.paddingTop = 0;
            el.style.paddingBottom = 0;
            el.style.marginTop = 0;
            el.style.marginBottom = 0;
            setTimeout(() => {
                el.style.display = "none";
                el.style.removeProperty("height");
                el.style.removeProperty("padding-top");
                el.style.removeProperty("padding-bottom");
                el.style.removeProperty("margin-top");
                el.style.removeProperty("margin-bottom");
                el.style.removeProperty("overflow");
                el.style.removeProperty("transition-duration");
                el.style.removeProperty("transition-property");
                el.style.removeProperty("transition-timing-function");
                el.classList.remove("is-open");
            }, duration);
        };
        
        // 要素をスライドしながら表示
        const slideDown = (el, duration = 300) => {
            el.classList.add("is-open");
            el.style.removeProperty("display");
            let display = window.getComputedStyle(el).display;
            if (display === "none") {
                display = "block";
            }
            el.style.display = display;
            let height = el.offsetHeight;
            el.style.overflow = "hidden";
            el.style.height = 0;
            el.style.paddingTop = 0;
            el.style.paddingBottom = 0;
            el.style.marginTop = 0;
            el.style.marginBottom = 0;
            el.offsetHeight;
            el.style.transitionProperty = "height, margin, padding";
            el.style.transitionDuration = duration + "ms";
            el.style.transitionTimingFunction = "ease";
            el.style.height = height + "px";
            el.style.removeProperty("padding-top");
            el.style.removeProperty("padding-bottom");
            el.style.removeProperty("margin-top");
            el.style.removeProperty("margin-bottom");
            setTimeout(() => {
                el.style.removeProperty("height");
                el.style.removeProperty("overflow");
                el.style.removeProperty("transition-duration");
                el.style.removeProperty("transition-property");
                el.style.removeProperty("transition-timing-function");
            }, duration);
        };
        
        // 要素をスライドしながら交互に表示/非表示
        const slideToggle = (el, duration = 300) => {
            if (window.getComputedStyle(el).display === "none") {
                return slideDown(el, duration);
            } else {
                return slideUp(el, duration);
            }
        };
        

        // /* =================================================== */
        // // アコーディオン
        // /* =================================================== */
        // // アコーディオンを全て取得
        // const accordions = document.querySelectorAll(".js-accordion");
        // // 取得したアコーディオンをArrayに変換(IE対策)
        // const accordionsArr = Array.prototype.slice.call(accordions);
        
        // accordionsArr.forEach((accordion) => {
        //     // Triggerを全て取得
        //     const accordionTriggers = accordion.querySelectorAll(".js-accordion-trigger");
        //     // TriggerをArrayに変換(IE対策)
        //     const accordionTriggersArr = Array.prototype.slice.call(accordionTriggers);
        
        //     accordionTriggersArr.forEach((trigger) => {
                
        //         const btn = trigger.querySelector(".js-accordion-btn");
        //         // Triggerにクリックイベントを付与
        //         btn.addEventListener("click", () => {
                    
        //             trigger.classList.toggle("is-active");
        //             btn.classList.toggle("is-active");
        //             const content = trigger.querySelector(".js-accordion__content");
        //             // 要素を展開or閉じる
        //             slideToggle(content);
        //         });
        //     });
        // });
    }
}